import React from 'react';
import Inner from '../components/templates/Inner';

import { Box, Divider, Text, Title } from '../components/atoms';

const Introduction = () => {
  return (
    <Inner title="Introduction">
      <Title as="h1" mb={64}>
        Introduction to .pulse
      </Title>
      <Box>
        <Title as="h3" size="h5" mb="s">
          Constant improvement
        </Title>
        <Text maxWidth={700}>
          We have created .pulse as the one source of truth for all processes and beliefs inside
          Heartbeat Agency. Like any tool or methodology, it will only work right when used right.
          And &quot;right&quot; can only be determined through a process of constant polishing and
          practice.
        </Text>
      </Box>
      <Divider mt={72} mb={64} />
      <Box maxWidth={700}>
        <Title size="h4" mb="xl">
          What are we trying to achieve?
        </Title>
        <Title as="h3" size="h5" mb="s">
          Hack the way we work
        </Title>
        <Text mb="l">
          Through documenting pre-tested, proven processes. Documenting gives us the ability to see
          the full process picture and improve specific parts through constant re-usage.
        </Text>
        <Title as="h3" size="h5" mb="s">
          Save time
        </Title>
        <Text mb="l">
          Split our website into components for better consistency, less technical debt, and faster
          updates.
        </Text>
        <Title as="h3" size="h5" mb="s">
          Build a culture
        </Title>
        <Text>
          Document and spread our core values to build the culture and find product teams and
          individuals with similar vision.
        </Text>
      </Box>
    </Inner>
  );
};

export default Introduction;
